import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import CreateClient from './createClient';
import FormIncomes from '../component/FormIncomes';
import { useDispatch, useSelector } from 'react-redux';
import { getClients, postIncomes } from '../redux/action';

export default function CreateIncomes() {
    const history = useHistory();
    const dispatch = useDispatch();
    const clients = useSelector(store => store.clients)
    const userIsPayment = useSelector(stee => stee?.userPremiumPay)
    const [disabled, setDisabled] = useState(false);
    const [addClient, setAddClient] = useState();
    const [ dataAdding, setDataAdding ] = useState();
    const [id, setId] = useState();
    const date = new Date();
    let dateForState;
    if (date?.getMonth() < 10) {
        dateForState = `${date?.getFullYear()}-0${date.getMonth()+1}-${date.getDate()}`;
    } if (date?.getDate() < 10) {
        dateForState = `${date.getFullYear()}-${date.getMonth()+1}-0${date.getDate()}`;
    } else {
        dateForState = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    }
    const [state, setState] = useState({
        concept: '',
        amount: 1,
        order_date: `${dateForState}`,
        method_payment: undefined,
        status_payment: '',
        client: {},
        status_order: '',
        details: '',
        items: ''
    })
    //Amout no es valido por eso no deja agregar, revisar que seria valido preguntandole a flavio.

    const [errors, setErrors] = useState({
        order_date: {
            bol: false,
            msg: ''
        },
        concept: {
            bol: false,
            msg: ''
        },
        amount: {
            bol: false,
            msg: ''
        },
        method_payment: {
            bol: false,
            msg: ''
        },
        status_payment: {
            bol: false,
            msg: ''
        },
        client: {
            bol: false,
            msg: ''
        },
        status_order: {
            bol: false,
            msg: ''
        },
        details: {
            bol: false,
            msg: ''
        },
        items: {
            bol: false,
            msg: ''
        }
    })



    useEffect(() => {
        
        setState({
            ...state,
            client: id
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        dispatch(getClients())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addClient])


    const onSubmit = (e) => {
        e.preventDefault();

        const err = {}

        const clientsChange = clients?.results?.find((e)=> e?.id === parseInt(state?.client));

        Object.keys(state).filter(x => x !== "details").map(x => {
            if (
                x === "status_order"
            ) {
                if (!state[x]) {
                    return err[x] = {
                        bol: true,
                        msg: 'Este campo no puede estar vacío'
                    };
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            }
            if (
                x === "order_date"
            ) {
                if (!state[x]) {
                    return err[x] = {
                        bol: true,
                        msg: 'Este campo no puede estar vacío'
                    };
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            }
            if (
                x === "method_payment" && !userIsPayment?.plan?.is_free
            ) {
                if (!state[x]) {
                    return err[x] = {
                        bol: true,
                        msg: 'Este campo no puede estar vacío'
                    };
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            }
            if (
                x === "status_payment" && !userIsPayment?.plan?.is_free
            ) {
                if (!state[x]) {
                    return err[x] = {
                        bol: true,
                        msg: 'Este campo no puede estar vacío'
                    };
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            }
            if (
                x === "items"
            ) {
                if (state[x] === '') {
                    return err[x] = {
                        bol: true,
                        msg: 'Debe haber almenos un producto agregado'
                    };
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            } else {
                return err[x] = {
                    bol: false,
                    msg: ''
                };
            }
        })

        setErrors(err);
        
        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            /* const dataSet = `${state.order_date} 00:00:00`;
            setState({
                ...state,
                order_date: dataSet
            }) */
            setDisabled(true)
            setTimeout(() => {
                setDisabled(false);
            }, 1500)
            dispatch(postIncomes(state, clientsChange, history))
        }
    }

    return (
        <>
            {
                <Modal show={addClient} className="modal-create">
                    <Modal.Header>
                        <h5 className="modal-title color-primary">{'Agregar cliente'}</h5>
                        <button type="button" className="close color-primary" onClick={() => setAddClient(false)}>
                            <span>×</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <CreateClient setAddClient={setAddClient} addClient={addClient} setId={setId} />
                    </Modal.Body>
                </Modal>
            }
            <FormIncomes
                title={'Carga de ventas'}
                state={state}
                setState={setState}
                back={'/incomes'}
                errors={errors}
                clients={clients}
                idClient={id}
                setAddClient={setAddClient}
                onSubmit={onSubmit}
                setDisabled={setDisabled}
                disabled={disabled}
                dataAdding={dataAdding}
                setDataAdding={setDataAdding}
            />
        </>
    )
}